<template>
    <!-- <div id="invoice"> -->
        <div v-if="show" class="invoice-box" id="invoice" :style="{'padding': '30px 15px','background-color': '#ffffff',
         'direction': (lang == 'ar'? 'rtl': 'ltr'), 'text-align': (lang == 'ar'? 'right': 'left') }">

         <div class="d-flex justify-content-between">
            <h3 class="mb-8"><b>{{$t('payment_print.receipt')}}</b></h3>
            <p>{{ $t('date') }}: {{ data.payment_date }} <span class="ml-15"> {{$t('number_no')}} : {{data.id}}</span></p>
         </div>

        <p>{{data.inventory? data.inventory.name : ''}}</p>
        <p>{{(data.inventory && data.inventory.city)? data.inventory.city.name+',' : ''}} {{data.inventory? data.inventory.address_1 : ''}}</p>
        <p>{{data.inventory? data.inventory.mobile : ''}}</p>
    
        <hr>

        <p>{{ $t('from') }}: {{data.supplier ? data.supplier.name : ''}}</p>

        <p>{{ $t('amount') }}: {{data.amount}} <span class="ml-15">{{ $t('paid_by') }}: {{data.payment_method_name }}</span></p>
        
        <p>{{ $t('invoice') }}: #{{data.invoice ? data.invoice.id : ''}}</p>

        <p>{{ $t('received_by') }}: {{data.collected_user_name }}</p>

        
        <div class="mb-5" style="width:250px; margin-top: 80px;">
            <hr class="mb-5">
            <p class="text-center"> {{$t('signature')}}</p>
        </div>

        </div>
    <!-- </div> -->
</template>

<script>
    import {jsPDF} from "jspdf";
    import html2canvas from "html2canvas";
    import ApiService from "@/core/services/api.service";
    import jsPdfExport from "@/core/config/jsPdfExport";


    export default {
        name: "sales-print",

        data() {
            return {
                lang: this.$i18n.locale,
                mainRoute: 'sales/payment_sales_invoices',
                data: {},
                idEditing: this.$route.params.id ? this.$route.params.id : null,
                action: this.$route.params.action ? this.$route.params.action : null,
                show: false,
            }
        },
        methods: {
            printInvoice() {
                // let element = document.getElementById('invoice');
                // let pdf = jsPDF("p", "pt", "a4");
                // html2canvas(element).then(canvas => {
                //     let image = canvas.toDataURL('image/png', 1.0);
                //     pdf.addImage(image, 'PNG', 45, 15);
                //     if (this.action == 'print'){
                //         pdf.autoPrint();
                //     }
                //     let _url = pdf.output("bloburl");
                //     location.href = _url;
                // });
              let _id = 'invoice';
              this.exportPDF(_id);
            },
          exportPDF(_id) {
            let that = this;
            // let pdf = jsPDF("p", "px", 'a4');
            //
            // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
            // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
            //
            // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");

            let pdf = jsPdfExport("p", "px", 'a4');
            pdf.html(document.getElementById(_id), {
              html2canvas: {
                scale: 0.5,
              },
              callback: function (pdf) {
                if (that.action == 'pdf') {
                  pdf.save('invoice');
                } else {
                  pdf.autoPrint();
                  pdf.output('dataurlnewwindow');
                }

                // var iframe = document.createElement('iframe');
                // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
                // document.body.appendChild(iframe);
                // iframe.src = pdf.output('datauristring');
              }
            });
          },
            async getData() {
                let _id = this.idEditing;
                   await ApiService.get(`${this.mainRoute}/${_id}`).then((response) => {
                        this.data = response.data.data;
                        this.show = true;
                    });
            },
        },
        mounted() {
            let promise = this.getData();
            Promise.all([promise]).then(()=>{
                this.printInvoice();
            });
            // window.print();
        }
    }
</script>

<style>
#invoice {
  font-family: "Amiri";
  width: 235mm;
}
    body{
        font-size: 14px;
    }
    canvas {
        height: 100;
    }
    .invoice-box {
        background-color: #fff;
        width:600px;
        margin: auto;
        padding: 30px;
        border: 1px solid #fff;
        font-size: 16px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #000;
    }
    
    .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
    }
    
    .invoice-box table td {
        /* padding: 5px; */
        vertical-align: top;
        font-size: 14px;
    }
    
    .invoice-box table tr.top td.title {
        font-size: 45px;
        line-height: 45px;
        color: #000;
    }
    .padd{
        padding-bottom: 100px;

    }
    .invoice-box table tr.information td {
        padding-bottom: 40px;
    }
    
    .invoice-box table tr.heading td {
        border: 1px solid #000 !important;
        background:#CAD3C8;
        border-bottom: 1px solid #fff;
        font-weight: bold;
    }

    .border {
        border: 1px solid #000 !important;
        padding: 5px;
    }

    .color{
        background-color: #CAD3C8;
    }
    
    .invoice-box table tr.details td {
        padding-bottom: 20px;
    }
    
    .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
    }
    
    .invoice-box table tr.item.last td {
        border-bottom: none;
    }
    
    .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
    }
    
    @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
        }
        .invoice-box table tr.information  td {
            width: 100%;
            display: block;
            text-align: center;
        }
    }
    /** RTL **/
    
    .invoice-box.rtl {
        direction: rtl;
        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    }
    
    .invoice-box.rtl table {
        text-align: right;
    }
    
    .invoice-box.rtl table tr td:nth-child(2) {
        text-align: left;
    }
    .table-bordered th, .table-bordered td {
        border: 1px solid #cdcdcd;
    }
    .table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #cdcdcd;
    }
    .table thead th, .table thead td {
        background-color: #f7f7f7;
    }
    .table.table-bordered tfoot th, .table.table-bordered tfoot td {
        border-bottom: 1px solid #cdcdcd;
    }
    .bg-cc{
        background-color: #f7f7f7;
    }
    .border-content{
        border: 1px solid #cdcdcd;
    }
    .box-title{
        background-color: #cdcdcd;
        width: 15px;
        height: 5px;
    }
</style>